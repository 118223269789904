/* Contenedor principal que incluye el título y el formulario */
.registro-container {
    margin-top: 20px;
    padding: 30px;
    background-color: #f4f4f4; /* Fondo neutro */
    border-radius: 15px; /* Bordes curvos */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle volumen */
    text-align: -webkit-center;
}

/* Espacio para la imagen de la marca */
.brand-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.brand-image {
    max-width: 150px;
    height: auto;
}

/* Estilo para el título del formulario */
.registro-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilo para campos de texto generales */
.registro-textfield {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff; /* Fondo blanco para el input */
    border-radius: 10px; /* Bordes curvos */
    padding: 12px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Sombra interna */
    border: 1px solid #ddd; /* Borde ligero */
}

/* Estilo específico para campos de fecha */
.registro-fecha {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 10px;
    padding: 12px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
}

/* Estilo específico para campos de teléfono */
.registro-phone {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 12px;
    /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); */
    border: 1px solid #ddd;
}

/* Estilo específico para el campo RUT */
.registro-RUT {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 12px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
}

.TextDisable {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #ccc;
    color: #888;
}

/* Estilo de texto en los inputs */
.registro-textfield input,
.registro-fecha input,
.registro-phone input,
.registro-RUT input {
    color: #333;
    font-size: 16px;
}

/* Estilo de etiquetas */
.registro-textfield label,
.registro-fecha label,
.registro-phone label,
.registro-RUT label {
    color: #555;
    font-weight: 500;
}

/* Estilo cuando el campo está enfocado */
.registro-textfield .Mui-focused,
.registro-fecha .Mui-focused,
.registro-phone .Mui-focused,
.registro-RUT .Mui-focused {
    color: #0056b3;
    border-color: #0056b3;
}

/* Estilo para mostrar errores */
.registro-textfield .Mui-error,
.registro-fecha .Mui-error,
.registro-phone .Mui-error, 
.registro-RUT .Mui-error {
    color: red;
}

/* Sección de la imagen del perfil */
.registro-example-image-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background: #f9f9f9; /* Fondo claro */
    border-radius: 15px;
    margin-bottom: 30px;
}

/* Imagen de perfil */
.registro-image-perfil,
.perfil_pso-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #000000;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para la imagen */
}

/* Botón para agregar imagen */
.ImageEditModal-add-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #000000;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ImageEditModal-add-image-button {
    font-size: 48px;
    color: #555;
    background: none;
    border: none;
    cursor: pointer;
}

/* Estilo del input de teléfono */
.custom-phone-input {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
}

/* Estilo del input dentro del PhoneInput */
.custom-phone-input .form-control {
    flex: 1;
    border: none;
    background: transparent;
    color: #333;
    font-size: 16px;
    padding: 8px;
}

.custom-phone-input .form-control:focus {
    outline: none;
    box-shadow: none;
}

/* Dropdown de banderas en el PhoneInput */
.custom-phone-input .flag-dropdown {
    margin-right: 8px;
    border-right: 1px solid #ccc;
    padding-right: 8px;
}

.custom-phone-input .country-list {
    z-index: 1000;
}

/* Responsividad para dispositivos móviles */
@media (max-width: 600px) {
    .custom-phone-input {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0;
    }

    .custom-phone-input .flag-dropdown {
        margin-right: 0;
        border-right: none;
        padding-right: 0;
    }

    .custom-phone-input .form-control {
        width: 100%;
        padding-left: 0;
    }
}

.react-tel-input .form-control {
    position: relative; 
    font-size: 14px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    height: 35px;
}
